import { theme } from '@airelogic/form-management/common';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { TssCacheProvider } from 'tss-react';
import App from './App';
import configureAxios from './AxiosSetup';
import ErrorBoundary from './ErrorBoundary';
import { logConsoleError } from './Services/Logging.Service';

window.onerror = logConsoleError;

configureAxios();

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

//when making changes here update the test-utils one... until we find a nice place to centralise it
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false, //we have axios retry for transient errors
      staleTime: 0,
      gcTime: 0, //Keep at 0 to globally disable cache. We can enable it inline as needed
    },
  },
});

const rootElement = document.getElementById('root') as HTMLElement;
Modal.setAppElement(rootElement);

createRoot(rootElement).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </TssCacheProvider>
        </CacheProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
