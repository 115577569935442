import {
  MustBePositive,
  ResourceKeyRegex,
  optionalXPathExpressionSchema,
} from '@airelogic/form-management/common/validations';
import { z } from 'zod';
import { DynamicDropdownIcon } from '../Assets/index';
import { DataType } from './DefaultSettings';
import { ControlDefinition } from './types';

export const validationSchema = z.object({
  resourceKey: z.string().min(1).regex(ResourceKeyRegex, 'Key must not contain whitespace'),
  resourceVersion: z.number().min(1, { message: MustBePositive }),
  choiceFilterXPath: optionalXPathExpressionSchema,
  style: z.enum(['default', 'buttons', 'buttons-full-width']),
  withOther: z.boolean(),
  loadInitialReferenceData: z.boolean(),
  dataSource: z.enum(['aireforms', 'custom-plugin', 'aireglu']),
});

export type AdditionalSettings = z.infer<typeof validationSchema>;

const dataTypes: DataType[] = [
  'string',
  'boolean',
  'email',
  'integer',
  'decimal',
  'date',
  'time',
  'datetime',
];

export const lazyDropdownControl: ControlDefinition<AdditionalSettings> = {
  displayName: 'Lazy Dropdown',
  icon: DynamicDropdownIcon,
  type: 'LAZY_SELECT',
  supportedDataTypes: dataTypes,
  additionalSettings: {
    validationSchema,
    defaults: {
      resourceKey: 'Animals',
      resourceVersion: 1,
      choiceFilterXPath: '',
      style: 'default',
      withOther: false,
      loadInitialReferenceData: false,
      dataSource: 'aireforms',
    },
  },
  formulaOverrides: {
    allowCalculatedValue: false,
    allowInitialValue: false,
  },
  supportsInitialValue: false,
};
