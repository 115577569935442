import { useControlSettingsContext } from '../ControlSettingsContext';
import { useRepeatSettingsContext } from '../RepeatSettingsContext';
import { useSectionSettingsContext } from '../SectionSettingsContext';

export const useNameValidationDeps = () => {
  const sectionSettings = useSectionSettingsContext();
  const controlSettings = useControlSettingsContext();
  const { fields: repeatSettings } = useRepeatSettingsContext();

  return [
    ...sectionSettings.map((_, index) => `sectionSettings.${index}.basicSettings.name`),
    ...controlSettings.map((_, index) => `controlSettings.${index}.basicSettings.name`),
    ...repeatSettings.flatMap((_, index) => [
      `repeatSettings.${index}.basicSettings.name`,
      `repeatSettings.${index}.contentSettings.customIterationName`,
    ]),
  ];
};
